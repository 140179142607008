  .custom-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    width: 100%;
  }
  
  .custom-input-label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(50%);
    transition: all 0.3s ease;
    color: #5B667A;
    pointer-events: none;
    font-size: 10px;
    font-family: Montserrat;
    background: white;
    padding: 0 4px;
  }
  
  .custom-input-wrapper.focused .custom-input-label {
    top: 0;
    left: 8px;
    color: #5B667A;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 4px;
  }
  
  .custom-input {
    padding: 12px 0px 0px 12px;
    transition: border-color 0.3s;
    border-radius: 10px;
    border: 1px solid #C2CEE3;
    color: #252528;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
  }
  input::placeholder + .custom-input-label {
    font-family: Montserrat;
  }
  .custom-input:focus {
    border-color: #252528;
    outline: none;
  }
  
  .custom-input.error {
    border-color: red;
  }
  
  .error-message {
    color: #D00;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  
  @media screen and (max-width: 375px) {
    .custom-input {
      padding: 0px 0px 0px 12px;
      font-size: 12px;
    } 
  }