/* PhoneNumberInput.css */
  .custom-select-tel-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100px;
  }

  .phone-input-container.error {
    border-color: red;
  }

  .phone-input-container {
    display: flex;
    align-items: center;
  }
  
  .phone-number-input {
    padding: 5px;
    border: 1px solid #ccc;
    flex-grow: 1;
  }

  .phone-input-container .custom-input {
    width: auto;
  }
  
  .phone-input-container .dropdown-wrapper .dropdown-header {
    border-radius: 10px 0 0 10px; 
    border-right: none; 
    padding-left: 0px;
  }

  .phone-input-container .custom-input-wrapper .custom-input {
    border-radius: 0 10px 10px 0; 
  }

  .phone-input-container .dropdown-wrapper .dropdown-header .dropdown-placeholder {
    padding-top: 16px;
  }

  .phone-input-container .custom-input-wrapper .custom-input {
    padding-top: 6px;
  }