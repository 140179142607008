  .app-header-link .main, .app-header-first .main {
    display: flex;
    width: 100%;
  }
  .app-header-link .main .countries-link {
    padding-top: 18px;
    margin-right: 0px;
    padding-right: 32px;
  }
  .app-header-first .main .logo-first {
    width: 100%;
  }