.app-screen-confirmation {
    background-color: #373D54;
    box-sizing: border-box;
    position: relative;
    height: 100vh;
}

.app-screen-confirmation .logo {
    padding-left: 79px;
    padding-top: 19px;
    text-align: left;
}

.app-screen-confirmation .title {
    width: 516px;
    color: #0BD;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    text-align: left;
    padding-left: 89px;
    padding-top: 62px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.app-screen-confirmation .subtitle {
    width: 521px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-left: 89px;
    box-sizing: border-box;
}

.app-screen-confirmation .subtitle span {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    box-sizing: border-box;
}

.app-screen-confirmation .steps {
    padding-top: 26px;
    padding-left: 89px;
    box-sizing: border-box;
    text-align: left;
}

.app-screen-confirmation .steps img {
    width: 362px;
    height: auto;
}

.app-screen-confirmation .people {
    position: absolute;
    top: 20%;
    right: 8%;
    height: 404px;
    width: 490px;
}

.app-screen-confirmation .people img {
    height: auto;
    width: 520px;
}

.app-screen-confirmation .img-tablet {
    display: none;
}

.app-screen-confirmation .img-mobile {
    display: none;
}

.app-screen-confirmation .flywire {
    color: white;
    width: 538px;
    font-size: 12px;
    font-family: Montserrat;
    padding: 16px 89px;
    text-align: left;
}

/********************Tablet***********************/
@media screen and (max-width: 990px) {
    .app-screen-confirmation {
        height: 100%;
    }
    .app-screen-confirmation .logo {
        padding: 0;
    }
    .app-screen-confirmation {
        display: grid;
        justify-content: center;
    }
    .app-screen-confirmation .title {
        font-size: 28px;
        text-align: center;
        width: 607px;
        
        padding-left: 0;
    }
    .app-screen-confirmation .subtitle {
        width: 521px;
        text-align: center;
        padding-left: 76px;
        height: auto;
        padding-bottom: 24px;
    }
    .app-screen-confirmation .people {
        display: none;
    }
    .app-screen-confirmation .steps {
        text-align: center;
        padding: 0;
    }
    .app-screen-confirmation .steps img {
        width: 308px;
        height: auto;
        padding-bottom: 24px;
    }
    .app-screen-confirmation .img-tablet {
        width: 607px;
        display: block;
    }

    .app-screen-confirmation .img-tablet img {
        width: 607px;
        height: auto;
    }

    .app-screen-confirmation .img-mobile {
        display: none;
    }
}

@media screen and (max-width: 440px) {
    .app-screen-confirmation {
        height: 100%;
    }
    .app-screen-confirmation .logo {
        padding: 0;
    }
    .app-screen-confirmation {
        display: grid;
        justify-content: center;
    }
    .app-screen-confirmation .title {
        width: 283px;
        font-size: 18px;
        line-height: 20px;
    }
    .app-screen-confirmation .subtitle {
        width: 283px;
        font-size: 14px;
        padding-left: 0;
    }
    .app-screen-confirmation .steps {
        padding-bottom: 24px;
    }
    .app-screen-confirmation .steps img {
        width: 283px;
        height: auto;
        margin-top: 0;
    }
    .app-screen-confirmation .img-tablet {
        display: none;
    }
    .app-screen-confirmation .img-mobile {
        width: 283px;
        display: block;
    }
    .app-screen-confirmation .img-mobile img {
        width: 283px;
    }
    .app-screen-confirmation .flywire {
        width: 283px;
        padding: 16px 0;
    }
}