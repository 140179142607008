/*************Global**************/
.app-header-first, .app-header-link {
    position: fixed;
    display: flex;
    height: 86px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    margin-bottom: 2px;
    z-index: 999;
}
.payment-body-link .payment-form-link {
    justify-content: center;
    padding-top: 102px;
}
.payment-body-link .data {
    padding-top: 89px;
}
.return {
    display: none;
}

@media screen and (max-width: 1320px) {
    .payment-body-table, .payment-body-table-update {
        width: 0px!important;
        display: none!important;
    }
    .payment-body-link .payment-form-link {
        width: 100%!important;
        padding-top: 17px;
    }
    .payment-body-link .payment-quotes-link .payment-data-link {
        display: block!important;
        text-align: left;
        padding-bottom: 37px;
    }
    .payment-body-link .payment-form-link .payment-data-link {
        display: block!important;
        text-align: left;
    }
}

@media screen and (max-width: 768px) {
    .payment-body-link .data {
        padding-top: 8px;
    }
    .payment-body-link .payment-quotes-link .payment-data-link {
        display: none!important;
    }
    .payment-body-link .payment-form-link .payment-data-link {
        display: block;
        text-align: left;
    }
}

/*************Payment**************/
.payment-body-table {
    padding-top: 59px;
    width: 488px!important;
}
.payment-body-link .payment-quotes-link .payment-data-link {
    display: none;
}
.information-t1 {
    display: none!important;
}

/*************Update Card**************/
.payment-body-table-update {
    background-color: #373D54;
    text-align: left;
    width: 488px;
    box-sizing: border-box;
    padding-left: 35px;
    padding-top: 118px;
    height: calc(100vh);
}

.payment-body-table-update .payment-data-link {
    width: 100%;
}

.payment-body-table-update .payment-data-link .title {
    color: #0BD!important;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.payment-body-table-update .payment-data-link .row {
    color: white!important;
}

.payment-body-link .payment-form-link {
    width: calc(100% - 488px);
}

.payment-form-link .container {
    padding: 16px 30px;
    box-sizing: border-box;
}

.payment-body-link .payment-form-link .title {
    color: #5B667A;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 7px;
    /*text-align: left;*/
    /*padding-left: 30px;*/
}

.payment-body-link .confirmation_continue .amount {
    color: #12A8FB;
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.payment-body-link .confirmation_continue .button {
    display: flex;
    border-radius: 20px;
    border: 2px solid #00D090;
    background: #00D090;
    gap: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    height: 50px;
    justify-content: center!important;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
    width: 80%;
  }

.information-t1, .information-t2 {
    display: none;
}

@media screen and (max-width: 1320px) {
    .information-t1 {
        margin-top: -28px;
        position: relative;
        color: #0BD;
        text-align: right;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        text-align: left;
        padding-left: 36px;
        display: flex;
        box-sizing: border-box;
    }
    .information-t2 {
        display: flex;
        
        background-color: #252528;
        color: #0BD;
        font-family: Montserrat;
        font-size: 16px;
        width: 100%;
        height: 72px;
        font-weight: 700;
        align-items: center;
        padding-left: calc((100% - 840px)/2);
        padding-top: 89px;
    }
    .information-t1 .click, .information-t2 .click {
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .information-t1 {
        position: relative;
        color: #0BD;
        text-align: right;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        text-align: left;
        padding-left: 36px;
        display: flex!important;
        box-sizing: border-box;
        padding-top: 50px;
    }
    .information-t2 {
        padding-left: 36px;
        padding-top: 176px;
    }
    .information-t1 .click, .information-t2 .click {
        cursor: pointer;
    }
    .payment-body-link .payment-form-link {
        padding-top: 8px;
    }
    .return {
        display: block;
        width: 35px!important;
    }
}