  .app-body-confirmation {
    background: #373D54;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    height: 469px;
    flex-shrink: 0;
    width: 100%;
    padding-top: 86px;
  }

  .app-body-confirmation .img {
    background-repeat: no-repeat;
    height: 60px;
    padding-top: 34px;
    background-position: center;
    background-size: 60px;
  }
  
  .app-body-confirmation .text {
    font-family: 'Montserrat';
    color: #FFF;
    text-align: center;
  }
  
  .app-body-confirmation .text .title {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 44px;
    width: 926px;
    margin: 0 auto;
    padding-bottom: 8px;
    color: #0BD;
  }
  
  .app-body-confirmation .text .subtitle {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    padding-bottom: 32px;
  }

  .app-body-confirmation .paymentInfoC {
    height: auto;
    width: 650px;
    border-radius: 10px;
    border: 1px solid #C2CEE3;
    background: #F6FBFF;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.10);
    margin: 0 auto;
    margin-top: 8px;
    color: #5B667A;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-bottom: 32px;
  }
  
  .app-body-confirmation .paymentInfoC .container {
    width: 80%; 
  }
  
  .app-body-confirmation .paymentInfoC .container .content {
    display: flex;
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #C2CEE3;
    color: #5B667A;
  }
  
  .app-body-confirmation .paymentInfoC .container:first-child {
    padding-top: 37px;
  }
  
  .app-body-confirmation .paymentInfoC .container:first-child .content {
    width: 100%;
  }
  
  .app-body-confirmation .paymentInfoC .container:last-child {
    border: none;
    color: #00D090;
    padding-bottom: 16px;
  }
  
  .app-body-confirmation .paymentInfoC .field {
    color: #0BD;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  
  .app-body-confirmation .paymentInfoC .container:last-child .content {
    border-bottom: none;
  }

  .app-body-confirmation .paymentInfoC .data {
    text-align: right;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 900;
    line-height: normal;
  }

  .app-body-confirmation .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
  }
  
  .app-body-confirmation .container-button .button-c {
    font-family: 'Montserrat';
    font-weight: 700;
    display: flex;
    border-radius: 20px;
    border: 2px solid #00D090;
    background: #00D090;
    gap: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media screen and (max-width: 768px) {
    .app-body-confirmation {
      height: 343px;
      padding-top: 104px;
    }
    .app-body-confirmation .img {
      height: 76px;
      padding-top: 22px;
      padding-bottom: 22px;
    }
    .app-body-confirmation .text .title {
      font-size: 18px;
      line-height: 20px;
      padding-left: 34px;
      padding-right: 34px;
      padding-bottom: 8px;
      width: 80%;
    }
    .app-body-confirmation .text .subtitle {
      font-size: 14px;
      line-height: 16px;
      padding-left: 40px;
      padding-right: 40px;
    }
    .app-body-confirmation .paymentInfoC {
      width: auto;
      margin-right: 40px;
      margin-left: 40px;
      margin-top: -16px;
      height: auto!important;
    }
    .app-body-confirmation .paymentInfoC .container .content {
      display: block!important;
    }
    .app-body-confirmation .paymentInfoC .container .content .data{
      padding-top: 9px;
      text-align: left;
      width: max-content;
    }
    .app-body-confirmation .paymentInfoC .container:first-child {
      padding-top: 20px;
    }
    .app-body-confirmation .container-button .button-c {
        width: 100%;
        margin-right: 40px;
        margin-left: 40px;
        box-sizing: border-box;
    }
  }