[class$="indicatorSeparator"] {
    display: none;
}

@media screen and (max-width: 768px) {
    .oleCountrySelect {
      width: 84px!important;
      font-size: 0px;
    }
    .dropdown-toggle {
      font-size: 0px!important;
      padding-left: 24px!important;
    }
    .dropdown-item {
      padding-left: 24px!important;
    }
}

/* Estilo para el contenedor principal */
.oleCountrySelect {
  position: relative;
  width: 200px; /* Ajusta el ancho según sea necesario */
}

/* Estilo para el botón del dropdown */
.dropdown-toggle {
  width: 100%; /* El ancho del botón coincide con el contenedor */
  padding: 10px; /* Espaciado interno */
  border: 1px solid #C2CEE3; /* Borde */
  border-radius: 10px; /* Borde redondeado */
  background: #F5F9FC; /* Fondo */
  cursor: pointer; /* Cursor de puntero */
  display: flex; /* Para alinear el contenido */
  align-items: center; /* Centrar verticalmente */
  justify-content: space-between; /* Justificar el contenido */
  color: #5B667A;
  font-family: 'Montserrat';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 88.889% */
}

button.dropdown-toggle:hover {
  background: #F5F9FC!important;
}

/* Estilo para el menú desplegable */
.dropdown-menu {
  position: absolute; /* Posición absoluta para desplegar el menú debajo del botón */
  top: 100%; /* Posiciona el menú debajo del botón */
  left: 0; /* Alineación a la izquierda */
  width: 100%; /* El menú ocupa el mismo ancho que el botón */
  background: white; /* Fondo blanco */
  border: 1px solid #C2CEE3; /* Borde */
  border-radius: 10px; /* Bordes redondeados */
  margin-top: 0px; /* Margen superior */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  z-index: 1; /* Asegura que el menú aparezca sobre otros elementos */
  list-style: none; /* Sin estilo de lista */
  padding: 0; /* Sin espaciado interno */
}

/* Estilo para los elementos de la lista */
.dropdown-item {
  padding: 10px; /* Espaciado interno */
  display: flex; /* Para alinear el contenido */
  align-items: center; /* Centrar verticalmente */
  cursor: pointer; /* Cursor de puntero */
  width: 100%; /* El elemento ocupa todo el ancho del menú */
}

/* Cambio de color de fondo al pasar el cursor */
.dropdown-item:hover {
  /*background: #F5F9FC;*/
}

/* Estilo para la opción seleccionada */
.selected {
  /*background: #E0F7FA;*/
}

.dropdown-icon {
  margin-left: auto; /* Empuja el icono hacia el extremo derecho */
  display: flex;
  align-items: center;
}

dropdown-toggle span {
  margin-right: 10px;
}