:root {
    --skeleton-background: #F4F5F9;
    --skeleton-border-radius: 16px;
  }
  
  .skeleton-header {
    display: flex;
    height: 86px;
    width: 100%;
    background-color: white;
  }
  
  .skeleton-header .logo,
  .skeleton-header .countries {
    display: flex;
    align-items: center;
  }
  
  .skeleton-header .logo {
    margin-left: 30px;
    width: calc(100% - 241px); /* Considerando el margen derecho de countries */
  }
  
  .skeleton-header .logo div,
  .skeleton-header .countries div {
    background-color: var(--skeleton-background);
    width: 210px;
    height: 60px;
    border-radius: var(--skeleton-border-radius);
  }
  
  .skeleton-header .countries {
    margin-right: 20px; /* Ajuste para compensar el margen izquierdo de logo */
  }
  
  .skeleton-header .countries div {
    width: 100%;
  }
    
  .skeleton-body {
    background: linear-gradient(90deg, var(--skeleton-background) 0.18%, var(--skeleton-background) 99.72%);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    height: 369px;
    flex-shrink: 0;
    width: 100%;
  }
  
  .skeleton-body .skeleton-title {
    height: 160px;
  }
  
  .skeleton-body .skeleton-form {
    height: 324px;
    width: 585px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin: 0 auto;
  }
  
  .skeleton-body .skeleton-form .skeleton-row {
    padding-top: 16px;
  }
  
  .skeleton-body .skeleton-form .skeleton-row:first-child {
    padding-top: 46px;
  }
  
  .skeleton-body .skeleton-form .skeleton-row .description,
  .skeleton-body .skeleton-form .skeleton-row .amount,
  .skeleton-body .skeleton-form .skeleton-row .flag,
  .skeleton-body .skeleton-form .button {
    background-color: var(--skeleton-background);
    margin: 0 auto;
    width: 70%;
    height: 30px;
    border-radius: var(--skeleton-border-radius);
  }
  
  .skeleton-body .skeleton-form .button {
    height: 60px;
  }
  
  .loading-container {
    display: relative;
    justify-content: center;
    align-items: center;
    margin: -86px;
  }
  
  .loading-container img,
  .loading-container p {
    font-size: 18px;
    font-family: 'Montserrat';
    text-align: center;
  }