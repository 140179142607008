.dropdown-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .dropdown-label {
    position: absolute;
    top: 20%;
    left: 12px;
    transform: translateY(50%);
    transition: all 0.3s ease;
    color: #5B667A;;
    pointer-events: none;
    font-size: 10px;
    font-family: Montserrat;
  }
  
  .dropdown-wrapper.open .dropdown-label,
  .dropdown-placeholder.has-value + .dropdown-label {
    top: 0;
    left: 8px;
    color: #5B667A;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 4px;
  }
  
  .dropdown-header {
    display: flex;
    align-items: left;
    justify-content: space-between;
    border: 1px solid #C2CEE3;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    text-align: left;
    height: 46px;
  }
  
  .dropdown-placeholder {
    flex-grow: 1;
    padding: 26px 12px 12px 12px;
    color: #252528;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  
  .dropdown-placeholder.has-value {
    color: #252528;
  }
  
  .dropdown-arrow {
    margin-left: 8px;
    font-size: 12px;
    padding: 10px;
    background-image: url('./../images/arrow_down.svg');
    background-repeat: no-repeat;
    background-position: right 12px center; /* Posiciona la flecha */
    background-size: 8px 8px;
  }

  .dropdown-list {
    position: absolute;
    border: 1px solid #C2CEE3;
    border-radius: 10px;
    background: white;
    max-height: 194px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    margin-top: -0.2px;
    border-top: none;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
  }
  
  .dropdown-filter {
    padding: 12px;
  }

  .search-container {
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    background-image: url('./../images/icon-search.svg'); /* Cambia esta ruta a la ubicación de tu ícono de búsqueda */
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    position: absolute;
  }

  .dropdown-filter input {
    width: calc(100% - 24px);
    padding: 12px;
    border: 1px solid #C2CEE3;
    border-radius: 8px;
    font-family: Montserrat;
    font-size: 14px;
    padding-left: 48px;
  }

  .dropdown-option {
    padding: 12px;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .dropdown-option:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown-header.error {
    border-color: red;
  }

  .error-message {
    color: #D00;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  
  .dropdown-wrapper.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .dropdown-header.disabled {
    cursor: not-allowed;
    background-color: #E5ECF8;
    color: #9BACCB;
  }
  
  .dropdown-placeholder.disabled {
    color: #a0a0a0;
  }
  
  .dropdown-filter input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  .dropdown-option.disabled {
    cursor: not-allowed;
    color: #a0a0a0;
  }
  
  .dropdown-option.disabled:hover {
    background-color: white;
  }

  @media screen and (max-width: 375px) {
    .dropdown-placeholder {
      font-size: 12px;
    } 
  }

  @media screen and (max-width: 424px) {
    .search-icon {
      margin-left: 8px;
    }
  
    .dropdown-filter input {
      padding: 12px;
      font-size: 13px;
      padding-left: 32px;
    }
  }