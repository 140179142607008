.app-header-link .logo-link {
  background-repeat: no-repeat;
  background-position: left;
  margin-left: 70px;
  height: 60px;
  width: 224px;
  padding-top: 17px;
}

.app-header-link .countries-link {
  margin-right: 72px;
  gap: 10px;
  width: 211px;
  padding-top: 23px;
}

.app-header-link .options-link {
  width: calc(100% - 224px - 211px - 72px - 30px);
  justify-content: right;
  align-items: center;
  display: flex;
  color: #5B667A;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
}

.app-header-link .options-link .option-payment {
  width: 180px;
  margin-right: 24px;
  cursor: pointer;
}

.app-header-link .options-link .option-update {
  width: 230px;
  margin-right: 70px;
  cursor: pointer;
}

.app-header-link .options-link .option-payment.active, .app-header-link .options-link .option-update.active {
  color: #12A8FB;
  font-weight: 700;
}
.app-header-link .options-link .option-payment.inactive, .app-header-link .options-link .option-update.inactive {
  color: #5B667A;
  font-weight: 400;
}

.payment-header-link {
  height: 178px;
  background: #373D54;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 183.333% */
  
  justify-content: center;
  width: 100%;
  padding-top: 86px;
}

.payment-header-link .return {
  position: absolute;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 40px 0px 0px 70px;
  box-sizing: border-box;
}

.payment-header-link .step {
  width: 100%;
  padding-top: 40px;
}

.payment-header-link .title {
  width: 100%;
}

.payment-header-link .paymentInfo {
  height: auto;
  width: 299px;
  border-radius: 10px;
  border: 1px solid #C2CEE3;
  background: #FFF;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.10);
  margin: 0 auto;
}

.payment-header-link .paymentInfo .description {
  padding-top: 30px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: #5B667A;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment-header-link .paymentInfo .amount {
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
  line-height: 48px;
  color: #12A8FB;
  padding-bottom: 30px;
}

.payment-body-link .payment-body-table .payment-data-link {
  width: 100%;
  flex-shrink: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 0px 16px 16px 70px;
  box-sizing: border-box;
  border-right: 1px solid #C2CEE3;
}

.payment-body-link .payment-quotes-link {
  width: 520px;  
  flex-shrink: 0;
  display: flex;
  text-align: center;
  justify-items: center;
}

.payment-body-link .payment-form-link .authorize {
  display: flex;
  justify-content: center;
  width: 100%;
}

.payment-body-link .payment-form-link .authorize .message {
  color: #5B667A;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 389px;
  flex-shrink: 0;
}

.payment-body-link .payment-data-link .title {
  color: #252528;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding-left: 6px;
  padding-bottom: 16px;
  flex-shrink: 0;
}

.payment-body-link .payment-data-link .row {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  line-height: 14px;
  color: #252528;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 4px;
  flex-shrink: 0;
  word-spacing: 3px;
}

.payment-body-link .payment-quotes-link .row:first-child {
  padding-bottom: 24px;
  line-height: 22px;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.payment-body-link .payment-data-link .row:nth-child(8) {
  padding-bottom: 312px;
}

.payment-body-link .payment-quotes-link .row {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  padding: 6px 0 6px 6px;
}

.payment-body-link .payment-data-link .line {
  width: 100%;
  height: 1px;
  background: #C2CEE3;
  margin-top: 17px;
}

.payment-body-link .payment-quotes-link .table table {
  width: 520px;
  color: #5B667A;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 16px;
}

.payment-body-link .payment-quotes-link .table table tbody tr td {
  border-bottom: 1px solid #C2CEE3;
  padding: 4px 0 4px 0;
  padding-bottom: 12px;
  padding-top: 12px;
}

/*.payment-body-link .payment-quotes-link .table table thead tr th:nth-child(2) {
  display: none;
}*/

/*.payment-body-link .payment-quotes-link .table table tbody tr td:nth-child(2) {
  display: none;
}*/

.payment-body-link .payment-quotes-link .flag {
  display: flex;
  
  padding-top: 23px;
  padding-bottom: 18px;
  color: #5B667A;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.payment-body-link .payment-quotes-link .avise {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 20px;
}

.payment-body-link .data {
  width: 100%;
}

.payment-body-link .confirmation_continue {
  border-radius: 10px;
  border: 1px solid #C2CEE3;
  background: #FFF;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.10);
  padding: 24px;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 32px;
}

.payment-body-link .confirmation_continue .title {
  color: #5B667A;
  text-align: center!important;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  
}

.fwpCloseLink {
  display: var(--fwpCloseLinkDisplay);
}

.fwpEmbedded .fwpIFrameContent {
  border: none!important;
}

.validation {
  color: #D00;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
}


.app-header-first .logo-first {
  background-repeat: no-repeat;
  background-position: left;
  margin-left: 30px;
  height: 60px;
  width: calc(100% - 211px);
  padding-top: 17px;
}

.app-header-first .countries-first {
  margin-right: 72px;
  gap: 10px;
  width: 211px;
  padding-top: 23px;
}

.app-header-first .options-first {
  display: none;
}


@media screen and (max-width: 768px) {
  .app-header-first, .app-header-link {
    height: 71px;
  }
  .app-header-first .logo-first {
    height: 32px;
    width: calc(100% - 90px);
    margin-top: 10px;
  }
  .app-header-first .countries-first {
    margin-right: 32px;
    width: 90px;
    padding-top: 17px;
  }
  .app-header-link {
    display: block;
    width: 100%;
    height: auto;
  }
  .app-header-link .main {
    display: flex;
    width: 100%;
  }
  .app-header-link .main .countries-link {
    padding-top: 32px;
    margin-right: 0px;
    padding-right: 32px;
  }
  .app-header-link .main .countries-link .oleCountrySelect {
    margin-left: auto;
  }
  .app-header-link .logo-link {
    /*background-position: center;
    height: 32px;
    /*width: 100%;*/
    margin-top: 17px;
    margin-left: 36px;
  }
  .app-header-link .options-link {
    width: 100%;
    height: 71px;
  }
  .app-header-link .options-link .option-payment {
    width: 50%;
    cursor: pointer;
    text-align: center;
    margin: auto;
  }
  .app-header-link .options-link .option-payment.active, .app-header-link .options-link .option-update.active {
    color: '#12A8FB';
    font-weight: 700;
  }
  .app-header-link .options-link .option-payment.inactive, .app-header-link .options-link .option-update.inactive {
    color: '#5B667A';
    font-weight: 400;
  }
  .app-header-link .options-link .option-update {
    width: 50%;
    cursor: pointer;
    text-align: center;
    margin: auto;
  }
  .payment-body-link .payment-data-link {
    display: none;
  }
  .payment-header-link {
    height: 128px;
    padding-top: 198px;
  }
  .payment-header-link .step {
    padding-top: 16px;
  }
  .payment-header-link .return {
    padding: 16px 0px 0px 24px; 
  }
  .payment-header-link .title {
    width: 100%;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  .payment-header-link .paymentInfo {
    margin-top: 8px;
  }
  .payment-header-link .paymentInfo .description {
    color: #5B667A;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 18px;
  }
  .payment-header-link .paymentInfo .amount {
    color: #12A8FB;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 18px;
  }
  /*.payment-body-link .payment-body-table {
    display: var(--fwpDisplayData);
    width: 100%;
  }*/
  
  .payment-body-link .payment-quotes-link .avise {
    display: none!important;
  }
  .payment-body-link .payment-quotes-link .row:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-body-link .payment-quotes-link .row:first-child div {
    width: 295px;
    text-align: center;
    padding-top: 32px;
  }
  .payment-body-link .payment-form-link {
    display: var(--fwpDisplayFormMobile);
    width: 100%;
  }
  .payment-body-link .payment-form-link .title {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    padding-left: 24px;
    padding-top: 8px;
    box-sizing: border-box;
  }
  .payment-body-link .payment-form-link .authorize .message {
    color: #5B667A;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0px 32px 32px 32px;
    box-sizing: border-box;
  }
  .payment-body-link .payment-body-table {
    display: none!important;
  }
  /*.app-header-link .countries-link {
    display: none;
  }*/
  .payment-body-link .payment-data-link {
    width: 100%;
  }
  .payment-body-link .payment-quotes-link {
    width: 100%;
    padding: 0;
    padding-bottom: 18px;
    display: var(--fwpDisplayData);
  }
  .payment-body-link .payment-quotes-link .confirmation_continue {
    border: none;
    box-shadow: none;
    width: 200px;
  }
  .payment-body-link .payment-quotes-link .confirmation_continue .title{
    display: none;
  }
  .payment-body-link .payment-quotes-link .confirmation_continue .amount{
    display: none;
  }
  .payment-body-link .payment-quotes-link .confirmation_continue .button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .payment-body-link .payment-quotes-link .table {
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .payment-body-link .payment-quotes-link .table table{
    width: 100%;
  }
  .payment-body-link .payment-quotes-link .table table tbody tr td{
    padding: 12px 8px 12px 6px;
  }
  .payment-body-link .payment-quotes-link .flag {
    padding-left: 32px;
    padding-right: 32px;
  }
  .payment-body-link .payment-quotes-link .table table thead tr th {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .payment-body-link .payment-quotes-link .table table thead tr th:nth-child(2) {
    display: block;
  }
  .payment-body-link .payment-quotes-link .table table tbody tr td:nth-child(2) {
    display: block;
    padding-bottom: 12px;
  }
  .payment-body-link .payment-quotes-link .sb{
    margin: 20px 16px 32px 16px;
  }
  .payment-body-link .payment-quotes-link .button {
    display: flex;
    border-radius: 20px;
    border: 2px solid #00D090;
    background: #00D090;
    width: 100%;
    gap: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  .validation {
    font-size: 12px;
  }
}

.skip {
  color: #00D090;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 8px;
}



/********************/

.app-header .logo {
  background-repeat: no-repeat;
  background-position: left;
  margin-left: 30px;
  height: 60px;
  width: calc(100% - 211px);
  padding-top: 17px;
}
.app-header .countries {
  margin-right: 72px;
  gap: 10px;
  width: 211px;
  padding-top: 23px;
}
.app-body {
  /*background: linear-gradient(90deg, #12A8FB 0.18%, #0BD 99.72%);*/
  background: #373D54;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  height: 260px;
  flex-shrink: 0;
  width: 100%;
  padding-top: 86px;
}
.app-body .img {
  background-repeat: no-repeat;
  height: 60px;
  padding-top: 34px;
  background-position: center;
  background-size: 60px;
  display: flex;
}

.app-body .text {
  font-family: 'Montserrat';
  color: #FFF;
  text-align: center;
}

.app-body .text .title {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  line-height: 44px;
  width: 926px;
  margin: 0 auto;
  padding-bottom: 8px;
  color: #0BD;
}

.app-body .text .subtitle {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  padding-bottom: 32px;
}

.app-body .paymentInfo {
  height: auto;
  width: 299px;
  border-radius: 10px;
  border: 1px solid #C2CEE3;
  background: #FFF;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.10);
  margin: 0 auto;
}

.app-body .paymentInfo {
  color: #5B667A;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
}

.data {
  width: 50%; /* Ambos elementos ocupan el 50% del ancho del contenedor */
}

.app-body .paymentInfo .description {
  padding-top: 30px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: #5B667A;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.app-body .paymentInfo .amount {
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
  line-height: 48px;
  color: #12A8FB;
  padding-bottom: 30px;
}

.app-body .paymentInfo .amount span{
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
  color: #12A8FB;
  font-weight: 700;
}

.app-body .paymentInfo .button {
  border-radius: 20px;
  border: 2px solid #00D090;
  background: #00D090;
  display: flex;
  width: 452px;
  height: 59px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  margin: 0 auto;
  cursor: pointer;
}

.app-body .paymentInfo .button .text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-body .paymentInfo .flag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 21px;
}

.app-body .img .return {
  display: flex;
  padding-left: 24px;
  margin-top: -16px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .app-header {
    height: 71px;
  }
  .app-header .logo {
    height: 32px;
    width: calc(100% - 90px);
  }
  .app-header .countries {
    margin-right: 32px;
    width: 90px;
    padding-top: 17px;
  }
  .app-body {
    height: 243px;
  }
  .app-body .img {
    height: 76px;
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .app-body .text .title {
    font-size: 18px;
    line-height: 20px;
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 8px;
    width: 80%;
  }
  .app-body .text .subtitle {
    font-size: 14px;
    line-height: 16px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .app-body .paymentInfo {
    margin-top: -6px;
    width: 198px;
    padding-left: 16px;
    padding-right: 16px;
    height: auto;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.10);
  }
  .app-body .paymentInfo .description {
    font-size: 14px;
    padding-top: 20px;
  }
  .app-body .paymentInfo .amount span {
    font-size: 16px;
    font-weight: 700;
  }
  .app-body .paymentInfo .amount {
    font-size: 18px;
    font-weight: 700;
    margin: -10px;
    padding-bottom: 20px;
  }
  .app-body .paymentInfo .button {
    width: 260px;
    padding: 15px 0;
    font-size: 18px;
    height: 24px;
  }
  .app-body .paymentInfo .flag {
    font-size: 14px;
    padding-top: 16px;
    padding-bottom: 21px;
  }
  .app-body .paymentInfoC {
    width: auto;
    margin-right: 40px;
    margin-left: 40px;
    margin-top: -16px;
    height: auto!important;
  }
  .app-body .paymentInfoC .container .content {
    display: block!important;
  }
  .app-body .paymentInfoC .container .content .data{
    padding-top: 9px;
    text-align: left;
  }
  .app-body .paymentInfoC .container:first-child {
    padding-top: 20px;
  }
  .app-body .img {
    height: 50px;
  }
  .app-body .img .return {
    padding-left: 20px;
    margin-top: -6px;
  }
}

@media screen and (max-width: 375px) {
  .app-body .text .title {
    font-size: 20px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .app-body .text .subtitle {
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .app-body .paymentInfo {
    width: 198px;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  .app-body .paymentInfo .description {
    font-size: 14px;
  }
  .app-body .paymentInfo .amount {
    font-size: 18px;
  }
  .app-body .paymentInfo .button {
    width: 260px;
    padding: 15px 0;
    font-size: 18px;
  }
  .app-body .paymentInfo .flag {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
}

.App {
  text-align: center;;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  height: 100%!important;
}

#embedTarget {
  height: 100%;
}

.paymentLogo {
  background-repeat: no-repeat;
  background-position: center;
  height: 60px;
  padding-top: 25px;
}

.paymentInfoHeader {
  position: 'relative';
  top: 0;
  left: 0;
  width: 100%;
  height: 161px;
  background: linear-gradient(88deg, #00D090 0.65%, #0BD 91.88%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentInfoHeader .text {
  font-size: 18px;
  font-family: 'Montserrat';
  color: #FFF;
}

.paymentInfoHeader .amount {
  height: '57px';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
}




.paymentInfoHeader .amount .value {
  font-size: 44px;
  font-family: 'Montserrat';
  color: #FFF;
}

.paymentInfoDetail {
  width: 100%;
  height: 100%;
  background-color: #F5F9FC;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 15px;
}

.paymentInfoDetail .row {
  font-size: 14px;
  font-family: 'Montserrat';
  text-align: left;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.paymentInfoDetail .payments {
  font-size: 16px;
  font-family: 'Montserrat';
  text-align: left;
  padding-left: 20px;
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 5px;
  font-weight: 700;
  color: #0BD;
}

.paymentInfoDetail .payments .title {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #0BD;
}

.paymentInfoDetail .payments table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
}

.paymentInfoDetail .payments table thead{
  color: #252528;
  font-weight: bolder;
}

.paymentInfoDetail .payments table tbody tr {
  border-bottom: 1px solid #AABAD6;
  color: #5B667A;
  font-weight: normal;
}

.paymentInfoDetail .payments table tbody tr:last-child {
  border-top: 1px solid #0BD;
  color: #0BD;
  font-weight: bold;
}

.paymentInfoDetail .payments table tbody tr td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.fwpEnvTag {
  display: none;
}

.paymentPendingScreen {
  height: calc(100vh - 288px);
  background-color: #373D54;
}
