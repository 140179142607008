.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dialog-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /*width: calc(100% - 64px);*/
    width: 654px;
    margin-right: 28px;
    margin-left: 28px;
  }
  
  .dialog__succes_flywire {
    width: 570px!important;
  }

  .dialog-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .dialog-body {
    text-align: center;
    padding-bottom: 48px;
  }

  .dialog-body h2 {
    color: #252528;
    font-size: 24px;
    font-family: 'Montserrat';
    padding-left: 56px;
    padding-right: 56px;
  }

  .dialog-body.dialog__succes_flywire h2 {
    color: #0BD;
    font-family: 'Montserrat';
    font-size: 20px;
    padding-left: 71px;
    padding-right: 71px;
  }

  .dialog-body.dialog__succes_flywire p {
    font-size: 20px;
  }

  .dialog__succes_flywire_subtitle {
    padding-left: 54px!important;
    padding-right: 54px!important;
    font-size: 20px;
    font-family: Montserrat;
  }

  .dialog-body .info {
    color: #0BD;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .dialog-body .information {
    display: grid;
    justify-content: center;
    text-align: left;
    padding-top: 16px;
  }

  @media screen and (max-width: 768px) {
    .dialog-body .information {
      display: grid;
    }
    .dialog-content {
      width: calc(100% - 64px)!important;
    }
    .dialog__succes_flywire {
      width: 100%!important;
      box-sizing: border-box;
    }
    .dialog-body h2{
      padding-left: 16px;
      padding-right: 16px;
    }
    .dialog-body.dialog__succes_flywire h2{
      padding-left: 16px;
      padding-right: 16px;
    }
    .dialog__succes_flywire_subtitle {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
  @media screen and (max-width: 1320px) {
    .dialog-body .information {
      display: block;
      width: 50%;
      margin: 0 auto;
    }
    .dialog-content {
      width: 654px;
    }
  }

  .dialog-body .information .key {
    color: #252528;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 205px;
  }

  .dialog-body .information .value {
    color: #252528;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 205px;
    padding-bottom: 12px;
  }

  .dialog-body p {
    color: #252528;
    font-size: 18px;
    font-family: 'Montserrat';
    padding-left: 48px;
    padding-right: 48px;
  }
  
  .dialog-body .icon img {
    padding-top: 50px;
    background-repeat: no-repeat;
  }

  .close-icon {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #00D090;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 24px;
  }
  
  button:hover {
    background-color: #00D090;
  }
  