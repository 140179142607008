.container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    box-sizing: border-box;
    margin: 0;
    padding: 20px;
}
.payerTitle {
    display: flex;
    width: 100%;
    text-align: left;
    justify-content: left;
    font-weight: 700;
    font-family: Montserrat;
}
.formData {
    max-width: 842px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;  
    justify-content: space-between;
    margin-top: 12px;
}
.col {
    /*background-color: #f0f0f0;
    border: 1px solid #ccc;*/
    padding: 0px;
    text-align: center;
    box-sizing: border-box;
    margin-top: -10px;
}
.col-12 { flex: 0 0 100%; }
.col-6  { flex: 0 0 calc(50% - 10px); }
.col-4  { flex: 0 0 calc(33.33% - 10px); }
.col-8  { flex: 0 0 calc(66.66% - 5px); }

.formData .button {
    margin-top: 22px;
    border-radius: 20px;
    border: 2px solid #00D090;
    background: #00D090;
    display: flex;
    width: 295px;
    padding: 15px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.disabled {
    /*background: #C2CEE3;
    border: 2px solid #C2CEE3;*/
    cursor: not-allowed;
}
.formData .button .text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.show {
    display: block;
}
.hide {
    display: none;
}

@media screen and (max-width: 768px) {
    .formData {
        width: auto!important;
        display: flex;
        flex-wrap: wrap;
        gap: 5px!important;  
        justify-content: space-between;
        margin-top: 12px;
    }

    .col-sm-12 { flex: 0 0 100%!important; }
    .col-sm-6  { flex: 0 0 calc(50% - 8px)!important; }
    .col-sm-4  { flex: 0 0 calc(33.33% - 5px)!important; }
    .col-sm-8  { flex: 0 0 calc(66.66% - 5px)!important; }

    .formData .button {
        width: 100%;
    }
}
