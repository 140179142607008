.oleCheckbox {
    display: flex;
    font-family: Montserrat;
}
.oleCheckbox>label {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .oleCheckbox>label>input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  .oleCheckbox>label>span {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #AABAD6;
    padding: 2px;
    
  }
  .oleCheckbox>label>span>img {
    width: 65%;
  }

  .oleCheckbox>span {
    margin-left: 12px;
    font-size: 14px;
    padding-top: 3px;
  }

  @media screen and (max-width: 768px) {
    .oleCheckbox>label {
      width: 12px;
      height: 12px;
    }
    .oleCheckbox>label>span {
      margin-top: -3px;
    }
    .oleCheckbox>span {
      margin-top: -7px;
    }
  }